<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-card title="Parents">
      <b-row>
        <!-- page length -->
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <!-- sorting -->
        <b-col md="3" sm="6" class="mt-1">
          <b-form-group
            label="Sort"
            label-cols-sm="2"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-25"
              >
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="3" class="mt-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- start data table -->
        <b-col cols="12">
          <b-table
            id="roleTable"
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(cover)="data"
              ><b-avatar :src="data.value"/>
            </template>
            <template #cell(name)="data">
              {{ data.item.item_translations.name }}
            </template>
            <template
              #cell(short_description)="data"
              v-html="data.item.item_translations.short_description"
            >
            </template>
            <template #cell(active)="items">
              <b-badge variant="primary">
                {{ items.item.active }}
              </b-badge>
            </template>
            <template #cell(actions)="items">
              <div>
                <!-- show button item -->
                <b-button
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
                  :to="{ path: '/ShowPhoneGuide/' + items.item.id }"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon
                    style="width: 18px; height: 18px"
                    icon="EyeIcon"
                    class="align-middle"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <!-- start pagination -->
          <b-pagination
            aria-controls="roleTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      // table fields
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'cover',
          label: 'Cover',
        },
        {
          key: 'active',
          label: 'Active',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'short_description',
          label: 'short_description',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },
        'actions',
      ],
      items: [],
      // permissions:[]
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.items.length
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    fetchData() {
      // request roles index
      axios
        .get('items-guide/parents/get')
        .then((result) => {
          this.items.length = 0
          this.items = result.data.data
          
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    // delete function to delete role
    //   deleteData(item) {
    //     axios.delete('role/delete/' + item).then((result) => {
    //       this.fetchData()
    //     }).catch((err) => {
    //       this.errors_back.length = 0
    //       if (err.response.data.data != null) {
    //         this.errors_back = err.response.data.data
    //         this.showDismissibleAlert = true
    //       } else {
    //         this.errors_back = []
    //         this.errors_back.push({
    //           error: 'this is server error'
    //         })
    //         this.showDismissibleAlert = true
    //       }
    //     });
    //   },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
